import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Signin from "@pages/Signin";
import SSDashboardLayout from "./layouts/DashboardLayout";
import ProductListing from "@pages/products/ProductListing";
import ProductDetail from "@pages/products/ProductDetail";
import AuthProvider from "./layouts/AuthProvider";
import LeadsListing from "@pages/leads/LeadsListing";

const Routes: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route path="/" exact>
            <Signin />
          </Route>
          <Route path="/dashboard" exact>
            <SSDashboardLayout title={"Dashboard"}></SSDashboardLayout>
          </Route>
          <Route path="/products" exact>
            <SSDashboardLayout title={"Products"}>
              <ProductListing />
            </SSDashboardLayout>
          </Route>
          <Route path="/products/:id" exact>
            <SSDashboardLayout title={"Product Detail"}>
              <ProductDetail />
            </SSDashboardLayout>
          </Route>
          <Route path="/leads" exact>
            <SSDashboardLayout title={"Leads"}>
              <LeadsListing />
            </SSDashboardLayout>
          </Route>
        </Switch>
      </AuthProvider>
    </Router>
  );
};

export default Routes;
