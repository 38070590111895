import { getIdToken } from "@libs/networking";
import { getManagementApi } from "@utilities/management-api";
import { Product } from "@libs/types/product";
import { Paginated } from "@libs/types/Paginated";
import { SWRConnector } from "@libs/types/SWRConnector";
import { mutate } from "swr";

const managementApi = getManagementApi();

export async function getAllProducts(
  limit?: number,
  cursor?: string
): Promise<Paginated<Product>> {
  return await managementApi.get("/products", { Authorization: getIdToken() });
}

export async function getProduct(id: number): Promise<Product> {
  return await managementApi.get(`/products/${id}`, {
    Authorization: getIdToken(),
  });
}

export async function uploadProductImage(file: File): Promise<string> {
  const resp = await managementApi.postFile(`/image-upload`, file);
  return resp.url;
}

export async function updateProduct(id: number, data: Partial<Product>) {
  const resp = await managementApi.put(`/products/${id}`, data, {
    Authorization: getIdToken(),
  });
  await mutate(`/management/v2/products`, (cached: Paginated<Product>) => {
    return cached.data.map((p) => {
      return p.id === resp.id ? resp : p;
    });
  });
  await mutate(`/management/v2/products/${id}`);
  return resp;
}

export function fetchProducts(): SWRConnector<Paginated<Product>> {
  return {
    cacheKey: "/management/v2/products",
    fetch(): Promise<Paginated<Product>> {
      return managementApi.get("/products?limit=999", {
        Authorization: getIdToken(),
      });
    },
  };
}

export function fetchProductDetail(id: number): SWRConnector<Product> {
  return {
    cacheKey: `/management/v2/products/${id}`,
    fetch(): Promise<Product> {
      return managementApi.get(`/products/${id}`, {
        Authorization: getIdToken(),
      });
    },
  };
}
