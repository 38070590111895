import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Product } from "@libs/types/product";
import FileUploader from "@components/organism/FileUploader";
import { updateProductInfo } from "./logic";

interface ProductInfoSectionProps {
  product: Product;
}

const ProductInfoSection: React.FC<ProductInfoSectionProps> = (props) => {
  const [sending, setSending] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const onFinish = async (values: Partial<Product>) => {
    if (imageUrl.length > 0) {
      values.imageUrl = imageUrl;
    }
    try {
      setSending(true);
      await updateProductInfo(props.product.id, values);
    } catch (err) {
      console.error(err);
    } finally {
      setSending(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  function onBannerUploaded(url: string) {
    console.log(url);
    setImageUrl(url);
  }

  return (
    <Form
      name="basic"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 16 }}
      initialValues={props.product}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Judul"
        name="title"
        rules={[{ required: true, message: "Please input your title!" }]}
      >
        <Input disabled={sending} />
      </Form.Item>

      <Form.Item
        label="Konten"
        name="content"
        rules={[{ required: true, message: "Please input your content!" }]}
      >
        <Input.TextArea rows={10} disabled={sending} />
      </Form.Item>

      <Form.Item
        label="Banner"
        name="imageUrl"
        rules={[{ required: true, message: "Please input your content!" }]}
      >
        <FileUploader onUploadSuccess={onBannerUploaded} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
        <Button
          disabled={sending}
          loading={sending}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProductInfoSection;
