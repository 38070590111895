import { RequestInstance } from "./network";

let managementApiInstance: RequestInstance | undefined;
let finxApiInstance: RequestInstance | undefined;

export function getManagementApi() {
  const url = process.env.REACT_APP_MANAGEMENT_API ?? "";
  if (!managementApiInstance) {
    managementApiInstance = new RequestInstance(url, {
      "Content-Type": "application/json",
    });
  }

  return managementApiInstance;
}

export function getFinxApi() {
  const url = process.env.REACT_APP_FINX_API ?? "";
  if (!finxApiInstance) {
    finxApiInstance = new RequestInstance(url, {
      "Content-Type": "application/json",
    });
  }

  return finxApiInstance;
}
