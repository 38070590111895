import { Leads } from "@libs/types/Leads";
import { formatDate } from "@utilities/date.utilities";
import { Col, Row, Divider } from "antd";
import React from "react";

interface LeadsViewProps {
  leads: Leads;
}

const DescriptionItem = ({
  title,
  content,
}: {
  title: string;
  content: JSX.Element | string;
}) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const LeadsView: React.FC<LeadsViewProps> = (props) => {
  return (
    <>
      <p
        className="site-description-item-profile-p"
        style={{ marginBottom: 24 }}
      >
        Profil
      </p>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Full Name"
            content={`${props.leads.firstName} ${props.leads.lastName}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Email" content={props.leads.email} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Phone" content={`${props.leads.phone}`} />
        </Col>
      </Row>
      <Row>
        <Col>
          <DescriptionItem
            title="Didaftarkan"
            content={`${formatDate(props.leads.createdAt)}`}
          />
        </Col>
      </Row>
      <Row>
        {props.leads.verifiedAt && (
          <Col span={12}>
            <DescriptionItem
              title="Terverifikasi"
              content={`${formatDate(props.leads.verifiedAt)}`}
            />
          </Col>
        )}
      </Row>
      <Divider />
      <p className="site-description-item-profile-p">Partner</p>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Full Name"
            content={props.leads.partner.name}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Email" content={props.leads.partner.email} />
        </Col>
      </Row>
    </>
  );
};

export default LeadsView;
