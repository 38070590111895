import React, { useState, useEffect } from "react";
import { Input, Select, Space, Cascader } from "antd";
import * as accounting from "accounting";

interface PriceInputProps {
  value?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  overrideAddon?: {
    before?: string;
    after?: string;
  };
}

const PriceInput: React.FC<PriceInputProps> = (props) => {
  const [displayValue, setDisplayValue] = useState<string>("");

  function interceptChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value && props.onChange) {
      e.target.value = "" + accounting.unformat(e.target.value);

      props.onChange(e);
    }
  }

  useEffect(() => {
    if (props.value) {
      setDisplayValue(accounting.formatNumber(props.value));
    }
  }, [props.value]);

  if (!props.overrideAddon) {
    return (
      <Input addonBefore="Rp" value={displayValue} onChange={interceptChange} />
    );
  } else {
    return (
      <Input
        addonBefore={props.overrideAddon.before}
        addonAfter={props.overrideAddon.after}
        value={displayValue}
        onChange={interceptChange}
      />
    );
  }
};

export default PriceInput;
