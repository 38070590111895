import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { refreshIdToken } from "@libs/firebase.auth";

const AuthProvider: React.FC = (props) => {
  const history = useHistory();
  const [refreshed, setRefreshed] = useState<boolean>(false);
  async function detectPrevSession() {
    try {
      await refreshIdToken();
      setRefreshed(true);
      const path = window.location.pathname;
      if (path === "/") {
        history.push("/dashboard");
      } else {
        history.push(path);
      }
    } catch (err) {
      setRefreshed(true);
      console.error(err);
      history.replace("/");
    }
  }

  useEffect(() => {
    detectPrevSession();
  }, []);
  return <div>{refreshed && props.children}</div>;
};

export default AuthProvider;
