import { getIdToken } from "@libs/networking";
import { Leads } from "@libs/types/Leads";
import { Paginated } from "@libs/types/Paginated";
import { getFinxApi } from "@utilities/management-api";

const finxApi = getFinxApi();

export async function getAllLeadProducts() {
  return await finxApi.get(`leads-product?per_page=9999`, {
    Authorization: getIdToken(),
  });
}

export async function getAllLeads(): Promise<Paginated<Leads>> {
  return await finxApi.get(`leads?per_page=9999`, {
    Authorization: getIdToken(),
  });
}
