import React, { useEffect, useState } from "react";
import { Button, Form, Select } from "antd";
import { Product } from "@libs/types/product";
import PriceInput from "@components/atoms/PriceInput";
import { updateProductInfo } from "@pages/products/logic";

interface ProductFeeSectionProps {
  product: Product;
}

const ProductFeeSection: React.FC<ProductFeeSectionProps> = (props) => {
  const [sending, setSending] = useState<boolean>(false);
  const [feeType, setFeeType] = useState<
    "amount" | "percentage" | undefined | string
  >(undefined);
  async function onFinish(values: Partial<Product>) {
    setSending(true);
    try {
      const updatedValues = {
        ...values,
        feeAmount: values.feeAmount ? Number(values.feeAmount) : undefined,
      };
      await updateProductInfo(props.product.id, updatedValues);
    } catch (err) {
      console.error(err);
    } finally {
      setSending(false);
    }
  }

  function onFinishFailed() {
    console.log("");
  }

  function onFormChange(val: "amount" | "percentage") {
    setFeeType(val);
  }

  useEffect(() => {
    if (props.product && props.product.feeType) {
      setFeeType(props.product.feeType);
    }
  }, [props.product]);

  return (
    <Form
      name="basic"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 16 }}
      initialValues={props.product}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Tipe Fee"
        name="feeType"
        rules={[
          { required: false, message: "Please input your recommendation!" },
        ]}
      >
        <Select onChange={onFormChange}>
          <Select.Option value="percentage">Persentase</Select.Option>
          <Select.Option value="amount">Nilai Fixed</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Nilai Fee"
        name="feeAmount"
        rules={[
          { required: false, message: "Please input your recommendation!" },
        ]}
      >
        <PriceInput
          overrideAddon={feeType === "percentage" ? { after: "%" } : undefined}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
        <Button
          disabled={sending}
          loading={sending}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProductFeeSection;
