let idToken = "";
const REFRESH_TOKEN_KEY = "ss-rt";
export function setIdToken(token: string): void {
  idToken = token;
}

export function getIdToken(): string {
  return idToken;
}

export function setRefreshToken(refreshToken: string): void {
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function getRefreshToken(): string | null {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY);
}

export function removeRefreshToken() {
  return sessionStorage.removeItem(REFRESH_TOKEN_KEY);
}
