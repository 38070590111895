import React from "react";
import MenuBar from "./MenuBar";

interface SSDashboardLayoutProps {
  title: string;
  subtitle?: string;
}

const SSDashboardLayout: React.FC<SSDashboardLayoutProps> = (props) => {
  return (
    <>
      <MenuBar />

      <div className="relative md:ml-64 bg-blueGray-50">
        <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
            {/* <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
              <div className="relative flex w-full flex-wrap items-stretch">
                <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                  <i className="fas fa-search" />
                </span>
                <input
                  type="text"
                  placeholder="Search here..."
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                />
              </div>
            </form> */}
          </div>
        </nav>
        {/* Header */}
        <div className="relative bg-brand md:pt-32 pt-12 pb-12">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div></div>
          </div>
        </div>
      </div>

      <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <div className="flex flex-wrap">
          <div className="w-full px-0 md:ml-64 mt-10">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h1 className="text-blueGray-700 text-2xl font-semibold">
                      {props.title}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="p-4 flex-auto">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SSDashboardLayout;
