import { uploadProductImage } from "@services/product.service";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

export async function uploadFile(
  uploadEvent: UploadChangeParam<UploadFile<any>>
): Promise<string> {
  let fileURL = "";
  if (uploadEvent.file.originFileObj) {
    fileURL = await uploadProductImage(uploadEvent.file.originFileObj);
  }
  return Promise.resolve(fileURL);
}
