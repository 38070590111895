import React, { useEffect, useState } from "react";
import { Button, Tag, Drawer, Space } from "antd";
import GenericTable, {
  GenericTableColumn,
} from "@components/molecules/GenericTable";
import { Leads } from "@libs/types/Leads";
import { getAllLeads } from "@services/leads.service";
import { formatDate } from "@utilities/date.utilities";
import LeadsView from "./LeadsView";

const LeadsListing: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [target, setTarget] = useState<Leads | null>(null);
  const [leads, setLeads] = useState<Leads[]>([]);

  const columns: GenericTableColumn<Leads>[] = [
    {
      title: "Nama Depan",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Nama Belakang",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Partner",
      key: "partner",
      render: (_, record) => <span>{record.partner.name}</span>,
    },
    {
      title: "Terferifikasi",
      key: "verified",
      filters: [
        {
          text: "Verified",
          value: "true",
        },
        {
          text: "Unverified",
          value: "false",
        },
      ],
      onFilter: (value, record) => {
        if (value === "true") return record.verifiedAt;
        if (value === "false") return !record.verifiedAt;
      },
      render: (_, record) =>
        record.verifiedAt ? (
          <Tag color="blue">Verified</Tag>
        ) : (
          <Tag color="red">Unverified</Tag>
        ),
    },
    {
      title: "Diverifikasi Pada",
      key: "verifiedAt",
      render: (_, record) => (
        <span>
          {record.verifiedAt ? `${formatDate(record.createdAt)}` : "-"}
        </span>
      ),
    },
    {
      title: "Didaftarkan",
      key: "createdAt",
      render: (_, record) => <span>{`${formatDate(record.createdAt)}`}</span>,
    },
    {
      title: "Action",
      render(_, record) {
        return <Button onClick={() => openLeadsDetail(record)}>Detail</Button>;
      },
    },
  ];

  function openLeadsDetail(record: Leads) {
    console.log(record);
    setTarget(record);
  }

  function cleanTarget() {
    setTarget(null);
  }

  async function fetchLeads() {
    setLoading(true);
    try {
      const resp = await getAllLeads();
      setLeads(resp.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchLeads();
  }, []);

  return (
    <>
      <GenericTable
        loading={loading}
        dataSource={leads}
        columns={columns}
        rowKey={(it: Leads) => `${it.id}-leads`}
      />
      <Drawer
        title="Informasi Lead"
        placement={"right"}
        width={500}
        onClose={() => {
          console.log("on close");
          cleanTarget();
        }}
        visible={target !== null}
      >
        {target && <LeadsView leads={target} />}
      </Drawer>
    </>
  );
};

export default LeadsListing;
